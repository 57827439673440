<template>
  <div>
    <van-nav-bar
      title="证件上传"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <br />
    <div class="boxcs">
      <div class="cardbox">
        <van-image width="100%" height="90%" :src="sfzimg.sfzf" />
        <div>
          <span style="color: red">*</span> <span>请上传身份证人像面</span>
        </div>
      </div>
      <br />
      <div class="cardbox">
        <van-image width="100%" height="90%" :src="sfzimg.sfzb" />
        <div>
          <span style="color: red">*</span> <span>请上传身份证国徽面</span>
        </div>
      </div>
    </div>
    <br />
    <div class="btns">
      <van-button type="primary" block>下一步</van-button>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
      sfzimg: {
        sfzf: require("../assets/scz.png"),
        sfzb: require("../assets/scf.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.boxcs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cardbox {
    border: 1px solid rgb(224, 222, 222);
    background-color: #fff;
    border-radius: 5px;
    width: 75%;
    height: 180px;
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }
}
.btns {
  padding: 10px;
}
</style>
